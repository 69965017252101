
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300);


$padding:2em;
$text-color:#fff;

body,html {
  padding:0;
  background-color:rgba(250,250,250,1);
  font-family: 'Raleway', sans-serif;
}

.liste {
  margin:0.5em;
  padding:0;
  list-style: none;

  li{
    padding:0.1em;
    color:#fff;
  }
}

.btn {
  margin:2em;
  border-radius:0;
  border:none;
}

.info-image {
  min-height:100vh;
  width:100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a, a:hover {
  text-decoration:none;
  color:$text-color;
  cursor:pointer;
}

#tool-nav {
    position:fixed;
    bottom:0;
    right:0.5em;
    padding:0.5em;
    z-index:1001;
    font-size:1.2em;

    .fa-stack {
      cursor:pointer;
      color:rgb(51,51,51);
    }
}


.formular {
  min-height:100vh;
  width:100%;

  z-index:-1;
  overflow:hidden;



  h1,h2,h3 {
    color:$text-color;
    padding:0;
    margin:0;
  }



  .buttons {
    margin:0;
    cursor:pointer;
    color:$text-color;
    font-size:1.1em;
  }

  .my-btn {
    margin:0.5em 0 0 0;
    padding:1em;
    text-align:left;

    .fa {
      cursor:pointer;
      position:absolute;
      display: absolute;
      top: 50%;
      right: 10px;
      transform: translate(-50%, -50%);
    }

  }


}

.info {
  padding:2em;
  color:#fff;
  line-height:1.3;
  font-size:1.3em;
}

#log {
  font-size:0.9em;
  background-color:rgba(255,255,255,0.9);

}

#headline {
  padding:2em;
  background-color:#fff;

  h2 {
    small {
      color:red;
    }
  }
}

.kachel {
  background-color:rgba(0,150,170,0.7);

  h1,h2,h3,.ja,.nein {
    padding:0.5em;
    color:#fff;
  }

  p {
    font-size:1.1em;
    color:#fff;
    padding:0.5em;
  }

  .buttons {
    margin:0;
    cursor:pointer;
    color:$text-color;
    font-size:1.1em;
  }

  .my-btn {
    margin:0.5em 0 0 0;
    padding:1em;
    text-align:left;

    .fa {
      cursor:pointer;
      position:absolute;
      display: absolute;
      top: 50%;
      right: 10px;
      transform: translate(-50%, -50%);
    }

  }

}
